@import "./_colors.scss";

#nt-typing {
  display: flex;
  width: 100%;
  height: 50px;
  background-color: #000000;
  color: $mdc-theme-primary;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.nt-typing__textbox {
  display: flex;
  width: 16.25rem;
  border-left: 0.15em solid $mdc-theme-primary;
  animation: blink-caret 0.75s step-end infinite;

  span {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    animation: typing 6s steps(40, end);
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: $mdc-theme-primary;
  }
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
