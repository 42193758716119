@import "./colors";
@import "./Typing.scss";

html,
body {
  background-color: $mdc-theme-primary;
  font-size: 16px;
  line-height: 20px;
}

figure {
  display: flex;
  flex-direction: row;
  justify-content: center;

  img {
    width: 100%;
    max-width: 520px;
    height: auto;
  }
}

section:nth-child(2) {
  margin-top: 2rem;
}
